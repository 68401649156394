import { render, staticRenderFns } from "./semanas.vue?vue&type=template&id=7e29027e&scoped=true&"
import script from "./semanas.vue?vue&type=script&lang=js&"
export * from "./semanas.vue?vue&type=script&lang=js&"
import style0 from "./semanas.vue?vue&type=style&index=0&id=7e29027e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e29027e",
  null
  
)

export default component.exports