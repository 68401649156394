<template>
    <section>
        <navbar-admin icon="icon-calendar-multiselect" />
         <div class="container">
             <div class="d-flex justify-content-center">
            <div class="contenedor-semanas d-inline-flex justify-content-center flex-wrap border py-1 br-4">
                <div v-for="(data,i) in 52" :key="i" class="div-semana text-center f-12">
                    {{ i + 1 }}
                </div>
            </div>
        </div>
        <!-- ejemplo -->
        <div class="d-none justify-content-center mt-4">
            <div class="contenedor-semanas d-inline-flex justify-content-center flex-wrap">
                <div class="div-semana text-center f-12">
                    <div class="wh-24 rounded-circle d-middle-center"> 1 </div>
                </div>
                <div class="div-semana text-center f-12">
                    <div class="wh-24 active rounded-circle d-middle-center"> 2 </div>
                </div>
                <div class="div-semana text-center f-12">
                    <div class="wh-24 some rounded-circle d-middle-center"> 3 </div>
                </div>
            </div>
        </div>
         </div>
        <div class="row mt-4 justify-content-center">
            <div class="col-12">
                <FullCalendar ref="fullCalendar" :options="calendarOptions"  class="fullcalendar-styles"/>
            </div>
        </div>
        <modal-ver-detalle ref="modalVerDetalle" />
    </section>
</template>

<script>
import moment from 'moment'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
// import modalVerDetalle from './partials/modalVerDetalle'
import { mapActions, mapGetters, mapMutations } from 'vuex'

function goToMes() {
    
}
export default {
    components:{
        modalVerDetalle: () => import('./partials/modalVerDetalle.vue'),
        // modalCrearTarea: () => import('./partials/modalCrearTarea.vue'),
        // modalDetalleTarea: () => import('./partials/modalDetalleTarea.vue'),
    },
    data() {
        return {
            prueba: 0,
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: 'timeGridWeek',
                locale: 'es',
                selectable: true,
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                    //right: 'Mes,Semana,Lista dayGridMonth,timeGridWeek,Lista'
                },
                dayHeaderFormat:{
                    weekday: 'short',
                    day: 'numeric'
                },
                allDayText: 'Día',
                buttonText:{
                    today: 'Hoy',
                    week: 'Semana',
                    month: 'Mes',
                },
                titleFormat:{
                    year: 'numeric', month: 'short', day: 'numeric' 
                },
                customButtons: {
                    Mes: {
                        text: 'Mes',
                        click(){
                            console.log($route);
                        }
                    },
                    Semana: {
                        text: 'Semana',
                        click(){
                             console.log('click Semana');
                        }
                    },
                    Lista: {
                        text: 'Lista',
                        click(){
                             console.log('click Lista');
                        }
                    },
                },
                dateClick: function(info) {
                    alert('clicked ' + info.dateStr);
                },
                // eventsSet: () => {
                //     this.listarCabecera()
                // },
                datesSet: (info) => {
                    this.listarCabecera(info)
                },
                eventClick: this.openModalVerDetalle,
                dayMaxEvents: true,
                allDaySlot: false,
                slotLabelFormat:{
                    hour12: false,
                    hour: '2-digit',
                    minute: '2-digit'
                },
                slotLabelInterval: "00:30:00",
                events: [],
            },
            id_equipo:{},
            id_equipos_modelos:{}
        }
    },
    computed: {
        ...mapGetters({
            calendarioSemana: 'calendario/calendario/calendarioSemana',
            cabecera: 'calendario/calendario/cabecera',
            equipos: 'calendario/calendario/equipos',
            fechaCalendario: 'calendario/calendario/fechaCalendario',

        }),
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.semanas'
        ]);
    },
    mounted () {
        this.setData();
    },
    methods: {
        ...mapActions({
            Action_get_detalle_tarea: 'calendario/calendario/Action_get_detalle_tarea',
            Action_get_cabecera_calendario: 'calendario/calendario/Action_get_cabecera_calendario',
            Action_get_calendario_semana: 'calendario/calendario/Action_get_calendario_semana',
        }),
        ...mapMutations({
            setFechaCalendario: 'calendario/calendario/setFechaCalendario',
        }),
        async openModalVerDetalle(info){
            let eventObj = info.event;
            this.$refs.modalVerDetalle.toggle(eventObj)
        },
        async setData(){
            this.calendarOptions.events = this.calendarioSemana
        },

        async listarCabecera(info){
            this.setFechaCalendario(moment(info.start).format('YYYY-MM-DD'))
            this.equipos.map(e => {
                if (this.cabecera.id_producto == e.id && e.tabla == 'productos') {
                    this.id_equipo = {id_producto:e.id, id_ops:e.id_ops}
                }else if(this.cabecera.id_producto  == e.id && e.tabla == 'equipos_modelos'){
                    this.id_equipos_modelos = {id_equipos_modelos:e.id, id_ops:e.id_ops}
                }
            })
            const payload = {
                semana:moment(this.fechaCalendario).add(1, 'days').week(),
                anio:moment(info.start).format('YYYY'),
                id_equipo:!_.isEmpty(this.id_equipo) ? this.id_equipo : null,
                id_equipos_modelos:!_.isEmpty(this.id_equipos_modelos) ? this.id_equipos_modelos : null,
            }
            // console.log("el payloaddddddddddd", payload);
            await this.Action_get_cabecera_calendario(payload)
            await this.Action_get_calendario_semana(payload)


        }
    },
}
</script>

<style lang="scss" scoped>
 .contenedor-semanas{
         max-width: 1161px;
         width: 1161px;
         min-width: 1161px;
        .div-semana{
            width: 43px;
            min-width: 43px;
            max-width: 43px;
            .active{
                background: var(--color-general);
                color: white;
            }
            .some{
                background: var(--color-db);

            }
        }
    }
</style>